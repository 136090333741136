// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-background-js": () => import("../src/pages/background.js" /* webpackChunkName: "component---src-pages-background-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-options-js": () => import("../src/pages/custom-options.js" /* webpackChunkName: "component---src-pages-custom-options-js" */),
  "component---src-pages-expanded-metal-js": () => import("../src/pages/expanded-metal.js" /* webpackChunkName: "component---src-pages-expanded-metal-js" */),
  "component---src-pages-galvanized-hardware-cloth-and-others-js": () => import("../src/pages/galvanized-hardware-cloth-and-others.js" /* webpackChunkName: "component---src-pages-galvanized-hardware-cloth-and-others-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-perforated-metal-js": () => import("../src/pages/perforated-metal.js" /* webpackChunkName: "component---src-pages-perforated-metal-js" */),
  "component---src-pages-success-js": () => import("../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-table-js": () => import("../src/pages/table.js" /* webpackChunkName: "component---src-pages-table-js" */),
  "component---src-pages-welded-wire-mesh-rolls-js": () => import("../src/pages/welded-wire-mesh-rolls.js" /* webpackChunkName: "component---src-pages-welded-wire-mesh-rolls-js" */),
  "component---src-pages-welded-wire-panels-js": () => import("../src/pages/welded-wire-panels.js" /* webpackChunkName: "component---src-pages-welded-wire-panels-js" */),
  "component---src-pages-woven-wire-cloth-js": () => import("../src/pages/woven-wire-cloth.js" /* webpackChunkName: "component---src-pages-woven-wire-cloth-js" */)
}

